import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LineMenu } from '../LineMenu'
import { logout} from '../../redux/actions/auth.actions'



const Header = () => {

  const dispatch = useDispatch()
  const history = useNavigate()

  const auth = useSelector(state => state.auth)

  const exitHendler = () => {
    dispatch(logout())
    history('/user/login')
  }

  return (
    <nav className="bg-white rounded   ml-[116px]  mr-[116px] mt-4">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        {/*LEFT side*/}
        <div className="flex gap-6 items-center">

          <LineMenu items={
            [



              { id: 0, text: 'Мои команды', href: `/main/myteams` },
              { id: 1, text: 'Merge Request Suggester', href: `/main/mrs` },
              { id: 2, text: 'Интеграции', href: `/main/integrations` },
              { id: 3, text: 'Профиль', href: `/main/profile` },
            ]
          } />

        </div>

        {/*RIGHT side*/}
        <div className="flex gap-1 items-center text-[20px] cursor-pointer hover:text-slate-400 "
          onClick={exitHendler}
        >
          Выйти
        </div>
      </div>
    </nav >
  )
}

export default Header