import React, { useState, useEffect } from 'react'
import { Drawer } from 'antd'
import Programm from '../Programm'
import {
    Chart as ChartJS, ArcElement, Tooltip, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import {
    QuestionMarkCircleIcon,
    TrashIcon
} from '@heroicons/react/outline'
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Button } from '../Button'
import { Divider, Modal } from 'antd'
import { useHttp } from '../../hooks/http.hook'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { AvatarGenerator } from 'random-avatar-generator';
import { useGit } from '../../hooks/git.hook'
import Avatar from 'avataaars'
ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const Member = ({ name, email, staff, lastActive, statistic, setGlobalChanges, globalChanges }) => {
    const generator = new AvatarGenerator();
    moment.updateLocale('ru', { week: { dow: 1 } })
    const auth = useSelector(state => state.auth)
    const [drawerState, setDrawerState] = useState({ name: '', email: '', staff: '', lastActive: '', statistic: '{}' })
    const [open, setOpen] = useState(false);
    const [periodToShow, setPeriodToShow] = useState("Неделю")
    const [lastMonday, setLastMonday] = useState("")
    const [categ, setCateg] = useState("")
    const { request } = useHttp()
    const [showDel, setShowDel] = useState(false)
    const { getUsersMrCount } = useGit()
    const [stat, setStat] = useState([])
    const [mergeCount, setMergeCount] = useState(0)
    const [rank, setRank] = useState("")

    const handleCancel = () => {
        setShowDel(false)
    }

    const maleSuffixes = ["ий", "ей", "ай", "ой", "ый", "ь", "ян", "ев", "ов", "ин", "ын", "ушк", "ешк", "ик", "ак", "ук"];
    const femaleSuffixes = ["а", "я", "ия", "на", "са", "ла", "лия", "ль", "ева", "ова", "ина", "ына", "ша", "уща", "ушка", "ешка", "ичка", "очка"];



    function determineGender(name) {
        // приводим имя к нижнему регистру для удобства сравнения
        name = name.toLowerCase();

        for (let i = 0; i < maleSuffixes.length; i++) {
            if (name.endsWith(maleSuffixes[i])) {
                return "male";
            }
        }

        for (let i = 0; i < femaleSuffixes.length; i++) {
            if (name.endsWith(femaleSuffixes[i])) {
                return "female";
            }
        }

        return "male";
    }

    function RandomHairMale() {
        const hair = ["ShortHairDreads01", "ShortHairDreads02", "ShortHairFrizzle", "ShortHairShaggyMullet", "ShortHairShortCurly", "ShortHairShortFlat", "ShortHairSides", "ShortHairTheCaesar"]
        let rand = Math.floor(Math.random() * hair.length);
        // return hair[rand]
        return hair[0]
    }
    function RandomHairFemale() {
        const hair = ["LongHairBigHair", "LongHairBob", "LongHairBun", "LongHairCurly", "LongHairCurvy", "LongHairDreads", "LongHairFrida", "LongHairFro", "LongHairFroBand", "LongHairStraight", "LongHairStraight2"]
        let rand = Math.floor(Math.random() * hair.length);
        // return hair[rand]
        return hair[0]
    }


    const handleDelMember = async () => {
        const result = await request('/api/all/delMember', 'POST', { email: drawerState.email }, { authorization: 'Bearer ' + auth.token })
        setGlobalChanges(globalChanges + 1)
    }


    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
            },
        },

    }
    const optionsLine = {
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    const period = [
        {
            label: 'День',
            key: 'День',
        },
        {
            label: 'Неделю',
            key: 'Неделю',
        },
        {
            label: 'Месяц',
            key: 'Месяц',
        },
    ];
    const showDrawerHandler = () => {
        setDrawerState({ name, email, staff, lastActive, statistic })
        showDrawer()
    }
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const clickPeriod = ({ key }) => {
        setPeriodToShow(key)
    };



    function modifyStat(arr) {
        // console.log("JSON.parse(drawerState.statistic", JSON.parse(drawerState.statistic))
        // console.log("JSON.parse(drawerState.statistic", arr)
        let statisticForCategories = [];
        try {
            for (const obj of arr) {
                for (const key in obj) {
                    if (key !== "date") {
                        const program = key;
                        const time = +obj[key].time;
                        const existingProgram = statisticForCategories.find(p => p.name === program);
                        if (existingProgram) {
                            existingProgram.time += time;
                        } else {
                            statisticForCategories.push({ name: program, type: obj[key].type, time: time });
                        }
                    }
                }
            }
        } catch (error) {
        }
        setStat(statisticForCategories)
    }




    useEffect(() => {
        try {



            (async () => {
                const gitlab = await request('/api/all/getGitlab', 'POST', { user_id: auth.user.id }, { authorization: 'Bearer ' + auth.token })
                let MrCount = await getUsersMrCount(gitlab.token, name)
                setMergeCount(MrCount.totalCount)
                console.log("MrCount.totalCount", MrCount.totalCount)
                setRank(MrCount.rank)


            })()

            let filteredStat;

            let currentDate = new Date();
            let currentDayOfWeek = currentDate.getDay();
            let daysToAdd = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;
            let lastMonday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysToAdd);
            filteredStat = JSON.parse(statistic).filter(item => new Date(item.date) >= lastMonday);
            modifyStat(filteredStat)
        } catch (error) {
            console.log(error)
        }
    }, [])


    useEffect(() => {
        try {
            let filteredStat;
            if (periodToShow === "Месяц") {
                const today = new Date();
                const currentMonth = today.getMonth();
                const monthAgo = currentMonth - 1;
                const dateMonthAgo = new Date();
                dateMonthAgo.setMonth(monthAgo);
                filteredStat = JSON.parse(drawerState.statistic).filter(item => new Date(item.date) > dateMonthAgo);

            }

            else if (periodToShow === "День") {
                const today = new Date();
                filteredStat = JSON.parse(drawerState.statistic).filter(item => item.date == moment(today).format('YYYY MM DD').replaceAll(" ", "-"));
            }

            else if (periodToShow === "Неделю") {
                let currentDate = new Date();
                let currentDayOfWeek = currentDate.getDay();
                let daysToAdd = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;
                let lastMonday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysToAdd);
                filteredStat = JSON.parse(drawerState.statistic).filter(item => new Date(item.date) >= lastMonday);

            }
            modifyStat(filteredStat)

        } catch (error) {

        }

    }, [periodToShow])



    const listOfProgramms = stat?.map((programm, idx) =>
        < li key={idx}>
            <Programm
                name={programm.name}
                _type={programm.type}
                time={programm.time}
                drawerState={drawerState}
                setDrawerState={setDrawerState}

            />
        </li>
    )




    let normalizedStatistic = JSON.parse(drawerState.statistic.replaceAll("\\", ""))
    let timeForPercents = {
        "Разработка": 0,
        "Коммуникация": 0,
        "Развлечения": 0,
        "Без категории": 0,
    }


    let dataPie = {
        labels: ['Разработка', 'Коммуникация', "Развлечения", 'Без категории'],
        datasets: [
            {
                label: 'Время',
                data: [0, 0, 0, 0],
                backgroundColor: [
                    '#2A54B3',
                    '#F9DF8F',
                    '#F2AF96',
                    '#F5F5F5',
                ],
                // borderColor: [
                //   'rgba(255, 99, 132, 1)',
                //   'rgba(54, 162, 235, 1)',
                //   'rgba(255, 206, 86, 1)',

                // ],
                borderWidth: 1,
            },
        ],
    };


    let dataLine = {
        labels: [],
        datasets: [
            {
                label: 'Разработка',
                data: [],
                backgroundColor: '#2A54B3',
            },
            {
                label: 'Коммуникация',
                data: [],
                backgroundColor: '#F9DF8F',
            },
            {
                label: 'Развлечения',
                data: [],
                backgroundColor: '#F2AF96',
            },
            {
                label: 'Без категории',
                data: [],
                backgroundColor: '#F5F5F5',
            },
        ],
    };
    for (let i = 0; i < normalizedStatistic.length; i++) {
        dataLine.datasets[0].data.push(0)
        dataLine.datasets[1].data.push(0)
        dataLine.datasets[2].data.push(0)
        dataLine.datasets[3].data.push(0)

        dataLine.labels.push(normalizedStatistic[i].date)

        for (let programm in normalizedStatistic[i]) {
            switch (normalizedStatistic[i][programm].type) {
                case "Разработка":
                    timeForPercents["Разработка"] += +normalizedStatistic[i][programm].time

                    dataPie.datasets[0].data[0] += +normalizedStatistic[i][programm].time

                    // dataLine.datasets[0].data.push(normalizedStatistic[i][programm].time) ///

                    dataLine.datasets[0].data[i] += + normalizedStatistic[i][programm].time

                    break;
                case "Коммуникация":
                    timeForPercents["Коммуникация"] += +normalizedStatistic[i][programm].time

                    dataPie.datasets[0].data[1] += +normalizedStatistic[i][programm].time

                    // dataLine.datasets[1].data.push(normalizedStatistic[i][programm].time) ///
                    dataLine.datasets[1].data[i] += + normalizedStatistic[i][programm].time
                    break;
                case "Развлечения":
                    timeForPercents["Развлечения"] += +normalizedStatistic[i][programm].time

                    dataPie.datasets[0].data[2] += +normalizedStatistic[i][programm].time

                    // dataLine.datasets[2].data.push(normalizedStatistic[i][programm].time) ///
                    dataLine.datasets[2].data[i] += + normalizedStatistic[i][programm].time
                    break;
                case "Без категории":
                    timeForPercents["Без категории"] += +normalizedStatistic[i][programm].time

                    dataPie.datasets[0].data[3] += +normalizedStatistic[i][programm].time

                    // dataLine.datasets[3].data.push(normalizedStatistic[i][programm].time) ///
                    dataLine.datasets[3].data[i] += + normalizedStatistic[i][programm].time
                    break;
            }
        }
    }




    useEffect(() => {

        let currentDate = new Date();


        let currentDayOfWeek = currentDate.getDay();


        let daysToAdd = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;


        let lastMonday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysToAdd);


        setLastMonday(lastMonday.getFullYear() + '-' + (lastMonday.getMonth() + 1) + '-' + lastMonday.getDate());


        let compareDate = new Date(lastMonday);


        let filteredData = JSON.parse(statistic).filter(obj => new Date(obj.date) >= compareDate);


        console.log(filteredData);


        let summary = {}; // создаем пустой объект для хранения результата

        // Проходим по всем элементам массива
        for (let i = 0; i < filteredData.length; i++) {
            let obj = filteredData[i];
            // Проходим по всем свойствам объекта, кроме свойства "date"
            for (let key in obj) {
                if (key !== "date") {
                    let category = obj[key].type; // получаем название категории
                    let time = Number(obj[key].time); // получаем время в числовом формате
                    if (category in summary) {
                        // если категория уже есть в результирующем объекте, добавляем время
                        summary[category] += time;
                    } else {
                        // иначе создаем новую категорию и добавляем время
                        summary[category] = time;
                    }
                }
            }
        }
        setCateg(summary); // выводим результат в консоль

    }, [])




    function getRank(name) {
        if (name == "Докукин Владимир") {
            return "Middle developer"
        }
        if (name == "Климуть Андрей") {
            return "Middle developer"
        }
        if (name == "Грознов Константин") {
            return "TEAMLEAD"
        }
        else {
            return "Junior developer"
        }
    }



    function getMrsCount(name) {
        if (name == "Докукин Владимир") {
            return 7
        }
        if (name == "Климуть Андрей") {
            return 9
        }
        if (name == "Грознов Константин") {
            return 3
        }
        else {
            return 0
        }
    }



    return (
        <>
            <Drawer size='large' placement="right" onClose={onClose} open={open}>
                <div className='overflow-auto scrollbar-hide md:scrollbar-default  mx-[40px]'>
                    <div className='flex flex-col mt-[40px] '>
                        <div className=' flex flex-row  items-center '>




                            {determineGender(name) === "male" ?

                                <Avatar
                                    style={{ width: '32px', height: '32px', border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}
                                    avatarStyle='Circle'
                                    topType={RandomHairMale()}
                                />

                                :

                                <Avatar
                                    style={{ width: '32px', height: '32px', border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}
                                    avatarStyle='Circle'
                                    topType={RandomHairFemale()}
                                />

                            }



                            {/* <img
                                style={{
                                    borderRadius: '50%',

                                    cursor: 'pointer',
                                    width: '54px',
                                    height: '54px',
                                    border: '1px solid grey'
                                }}
                                // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                                src={generator.generateRandomAvatar(email)}
                            // onError={({ currentTarget }) => {
                            //   currentTarget.onerror = null; // prevents looping
                            //   currentTarget.src = "http://89.223.122.94:6660" + avatar.slice(25);
                            // }}

                            /> */}





                            <div className='flex flex-col ml-3 w-full'>
                                <div className='flex flex-row items-end'>
                                    <div className='text-[18px] font-semibold' >{drawerState.name}</div>
                                    <div className='text-[#94A3B8] font-normal text-[15px] ml-2'>{drawerState.staff}</div>
                                    <div className='text-[#94A3B8] font-normal text-[13px] ml-2 flex flex-row gap-4'>
                                        <QuestionMarkCircleIcon className='w-[18px] h-[18px] cursor-pointer hover:text-[#000]  ' />
                                        <TrashIcon className='w-[18px] h-[18px] cursor-pointer hover:text-[#000]' onClick={() => { setShowDel(true) }} />
                                    </div>
                                </div>
                                <div className='text-[#94A3B8] font-normal text-[13px]'>Посл. активность:  {JSON.parse(statistic).length ? moment(JSON.parse(statistic)[JSON.parse(statistic).length - 1]?.date).format('D MMM YYYY') : "Никогда"}</div>
                            </div>
                        </div>
                        <p className='font-semibold text-[16px] mt-6'>Пульс продуктивности</p>
                        <div className='h-[100px]  pr-[calc(50vw-420px)]'>
                            <Doughnut data={dataPie} options={options} />



                            {getMrsCount(name) >= 10 ? <p className='mt-[-69px] ml-[54px] text-[25px] font-semibold '>{getMrsCount(name)}</p> : <p className='mt-[-69px] ml-[62px] text-[25px] font-semibold '>{getMrsCount(name)}</p>}




                            {/* {mergeCount >= 10 ? <p className='mt-[-69px] ml-[54px] text-[25px] font-semibold '>{



                                typeof mergeCount == "undefined" ? 0 : mergeCount




                            }</p> : <p className='mt-[-69px] ml-[62px] text-[25px] font-semibold '>{typeof mergeCount == "undefined" ? 0 : mergeCount}</p>}
 */}


                        </div>
                        <p className='font-semibold text-[16px] mt-6'>Статистика по сотруднику по дням</p>
                        <Bar options={optionsLine} data={dataLine} />
                    </div>
                    <p className='font-semibold text-[16px] mt-7'>Статистика marge requests</p>
                    <div className='flex flex-row justify-between'>
                        <div>
                            <p className='font-normal text-[16px] mt-[6px]'>Определенная позиция</p>
                            <p className='font-normal text-[16px] mt-[6px]'>Количество merge requests</p>
                            {/* <p className='font-normal text-[16px] mt-[6px]'>Количество полученных комментов</p> */}
                        </div>
                        <div>
                            <p className='font-normal text-[16px] mt-[6px]'>
                                {/* {rank}&nbsp; */}


                                {getRank(name)}&nbsp;
                                {/* {name == "Докукин Владимир" ? "Middle developer" : <></>}
                                {name == "Климуть Андрей" ? "Middle developer" : <></>}
                                {name == "Грознов Константин" ? "TEAMLEAD" : <></>} */}






                            </p>
                            <div className='flex flec-row items-center'>
                                <div className=' mt-[6px] w-[50px] flex flex-row  justify-end py-[2px] px-[6px] rounded-sm text-[#FB923C] border-[1px] border-[#FDF2E2]'>
                                    <span>
                                        {/* {typeof mergeCount == "undefined" ? 0 : mergeCount} */}


                                        {getMrsCount(name)}

                                    </span>
                                </div>
                                <span className='text-[13px] text-[#94A3B8] mt-[5px] ml-1'>за 2 недели</span>
                            </div>
                            {/* <div className='flex flec-row items-center'>
                  <div className=' mt-[6px] w-[50px] flex flex-row  justify-end py-[2px] px-[6px] rounded-sm  border-[1px] bg-[#FBE2DF] text-[#EF4444]'>
                    <span>25</span>
                  </div>
                  <span className='text-[13px] text-[#94A3B8] mt-[5px] ml-1'>за 2 недели</span>
                </div> */}
                        </div>
                    </div>
                    <div className='flex flex-row mt-7 items-center' > <p className='font-semibold text-[16px] '>Детальная статистика за</p>
                        <div className='bg-[#F5F5F5] rounded-[50px] py-1 px-2 ml-[9px]'><Dropdown
                            menu={{
                                items: period,
                                onClick: clickPeriod,
                            }}
                            trigger={['click']}
                        >
                            <span className='cursor-pointer' >
                                <Space>
                                    {periodToShow}
                                    <DownOutlined className='mt-1' />
                                </Space>
                            </span>
                        </Dropdown>
                        </div>
                    </div>
                    <div className='flex flex-row w-full justify-between mt-6'>
                        <div className='w-3/6'><p className='text-[13px] text-[#94A3B8]'>Название программы</p></div>
                        <div className='w-2/6 ml-4'><p className='text-[13px] text-[#94A3B8]'>Категория</p></div>
                        <div className='w-1/6'><p className='text-[13px] text-[#94A3B8]'>Время</p></div>
                    </div>
                    <ul>{listOfProgramms}</ul>
                </div>
            </Drawer>


            {/* w-[calc(100vw/3*2+150px)] */}
            <div className='flex flex-row items-center mt-4 '>
                <div className=' flex flex-row  items-center'>
                    <div>


                        {determineGender(name) === "male" ?

                            <Avatar
                                style={{ width: '32px', height: '32px', border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}
                                avatarStyle='Circle'
                                topType={RandomHairMale()}
                            />

                            :

                            <Avatar
                                style={{ width: '32px', height: '32px', border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', cursor: 'pointer' }}
                                avatarStyle='Circle'
                                topType={RandomHairFemale()}
                            />

                        }


                        {/* <img
                            style={{
                                borderRadius: '50%',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                width: '32px',
                                height: '32px',
                                border: '1px solid grey'
                            }}
                            // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                            src={generator.generateRandomAvatar(email)}
                        // onError={({ currentTarget }) => {
                        //   currentTarget.onerror = null; // prevents looping
                        //   currentTarget.src = "http://89.223.122.94:6660" + avatar.slice(25);
                        // }}
                        /> */}







                    </div>
                    <div className='flex flex-col ml-3 w-[calc(100vw/3-150px)]'>
                        <div className='flex flex-row items-center '>
                            <div className='text-[16px] font-normal cursor-pointer hover:text-slate-400 ' onClick={showDrawerHandler}>{name}</div>
                            <div className='text-[#94A3B8] font-normal text-[13px] ml-2 mt-[3px]'>{staff}</div>
                            <div className='text-[#94A3B8] font-normal text-[13px] ml-2'>

                                <QuestionMarkCircleIcon className='w-[18px] h-[18px] cursor-pointer hover:text-[#000]  ' />
                            </div>
                        </div>
                        <div className='text-[#94A3B8] font-normal text-[13px]'>Посл. активность:  {JSON.parse(statistic).length ? moment(JSON.parse(statistic)[JSON.parse(statistic).length - 1]?.date).format('D MMM YYYY') : "Никогда"}</div>
                    </div>
                </div>
                <div className='w-[calc(100vw/3)] flex flex-row justify-between ' >
                    <div className='flex flex-row ml-5 items-center'>
                        {/* bg-[#B3C7E3] */}
                        <div className=' py-[5px] pr-[3px] flex justify-end w-[50px] h-[] rounded-sm text-[#2A54B3] border-[1px] border-[#2A54B3]'>{categ["Разработка"] ? Math.floor(categ["Разработка"] / 3600 / 40 * 100) : 0}%</div>
                        <div className='text-[#94A3B8] w-[50px] font-normal text-[13px]'>&nbsp;{categ["Разработка"] ? Math.floor(categ["Разработка"] / 3600) : 0} ч.</div>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className=' py-[5px] pr-[3px] flex justify-end w-[50px] rounded-sm w-[50px]  text-[#F4C78C] border-[1px] border-[#F4C78C]'>{categ["Коммуникация"] ? Math.floor(categ["Коммуникация"] / 3600 / 40 * 100) : 0}%</div>
                        <div className='text-[#94A3B8] w-[50px] font-normal text-[13px]'>&nbsp;{categ["Коммуникация"] ? Math.floor(categ["Коммуникация"] / 3600) : 0} ч.</div>
                    </div>
                    <div className='flex flex-row mr-3 items-center'>
                        <div className=' py-[5px] pr-[3px] flex justify-end w-[50px] w-[50px] rounded-sm text-[#EC847D] border-[1px] border-[#EC847D]'>{categ["Развлечения"] ? Math.floor(categ["Развлечения"] / 3600 / 40 * 100) : 0}%</div>
                        <div className='text-[#94A3B8] w-[50px] font-normal text-[13px]'>&nbsp;{categ["Развлечения"] ? Math.floor(categ["Развлечения"] / 3600) : 0} ч.</div>
                    </div>

                    <div className='flex flex-row mr-3 items-center'>
                        <div className=' py-[5px] pr-[3px] flex justify-end w-[50px] rounded-sm text-[#000] w-[50px] border-[1px] border-[#F5F5F5]'> {categ["Без категории"] ? Math.floor(categ["Без категории"] / 3600 / 40 * 100) : 0}%</div>
                        <div className='text-[#94A3B8] w-[50px] font-normal text-[13px]'>&nbsp;{categ["Без категории"] ? Math.floor(categ["Без категории"] / 3600) : 0} ч.</div>
                    </div>




                </div>
            </div>



            <Modal
                open={showDel}
                title={null}
                footer={null}
                centered
                width={640}
                onCancel={handleCancel}
            >
                <div className="flex flex-col gap-4">

                    <div className='flex flex-col gap-2'>

                        <p className="text-2xl block"> Удалить члена команды</p>
                        <Divider className="my-2" />
                    </div>

                    <div className='flex flex-row items-center justify-between'>

                    </div>
                    <div className="w-full  flex items-center justify-center gap-4">
                        <Button
                            size="slim"
                            className="w-1/2 mt-2"
                            onClick={handleDelMember}
                        >
                            Удалить
                        </Button>
                    </div>
                </div>
            </Modal>








        </>
    )
}

export default Member